enum USER_ROLE {
  admin_value = 1,
  user_value = 2,
  admin_label = "Admin",
  user_label = "Customer",
}

enum USER_STATUS {
  active_value = 0,
  suspended_value = 1,
  unconfirmed_value = 2,
  malicious_value = 3,
  active_label = "Active",
  suspended_label = "Suspended",
  unconfirmed_label = "Unconfirmed",
  malicious_label = "Malicious",
}

enum USER_ACTION {
  edit_value = 0,
  set_pass_value = 1,
  copy_rate_value = 2,
  sign_in_his_value = 3,
  show_user_with_sane_value = 4,
  sus_user_value = 5,
  active_value = 6,
  active_label = "Active user",
  edit_label = "Edit user",
  set_pass_label = "Set password",
  copy_rate_label = "Copy rates",
  sign_in_his_label = "Sign-in history",
  show_user_with_sane_label = "Show users with same IPs",
  sus_user_label = "Suspend user",
}

export { USER_ROLE, USER_STATUS, USER_ACTION };
