export const LOGIN = "/admin/login";
export const STATISTIC = "/admin/statistic";
export const USERS = "/admin/users";
export const ORDERS = "/admin/orders";
export const CANCEL = "/admin/cancel";
export const SERVICES = "/admin/services";
export const PAYMENTS = "/admin/payments";
export const TICKETS = "/admin/tickets";
export const REPORTS = "/admin/reports";
export const APPEARANCE = "/admin/appearance";
export const SETTINGS = "/admin/settings";
export const NOT_FOUND = "*";
