import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import HeaderLayout from "./subcomponents/HeaderLayout";
import SideBar from "./subcomponents/SideBar";
import "./style.scss"
import { useEffect, useState } from "react";
import Breadcrumbs from "./subcomponents/Breadcrumbs";
import { useMediaQuery } from "react-responsive";
import ClickOutside from "components/ClickOutside";

const MainLayout = ({ seoData }: { seoData: any }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 640px)' })
  const [openShoppingCart, setOpenShoppingCart] = useState<boolean>(false);

  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const openCart = () => {
    setOpenShoppingCart(true);
  };

  const closeCart = () => {
    setOpenShoppingCart(false);
  };

  useEffect(() => {
    if (isTabletOrMobile) {
      document.body.style.overflow = !collapsed ? "hidden" : "auto";
    } else {
      document.body.style.overflowY = "";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isTabletOrMobile, collapsed]);

  return (
    <div className="min-h-[100vh] w-full">
      {/* <ShoppingDrawer
              isOpenCart={openShoppingCart}
              closeCart={closeCart}
              /> */}
      {isTabletOrMobile && !collapsed && <div className="blurred-overlay"></div>}
      <HeaderLayout
        collapsed={collapsed}
        setCollapsedMenu={toggleCollapsed}
        openShoppingCart={openCart}
        seoData={seoData}
      />

      <Layout style={{ background: "#fff" }} className={`w-full bg-lightModeBg`} hasSider={true}>
        {/* <Layout style={{ display: "flex" }}> */}
        {isTabletOrMobile ? (
          <ClickOutside onClick={() => setCollapsed(true)} className="overflow-auto">
            <SideBar collapsed={collapsed} setCollapsed={setCollapsed} seoData={seoData} />
          </ClickOutside>
        ) : (
          <SideBar collapsed={collapsed} setCollapsed={setCollapsed} seoData={seoData} />
        )}
        <Layout style={{
          marginLeft: collapsed ? isTabletOrMobile ? 10 : 72 : isTabletOrMobile ? 10 : 256,
          transition: "margin .2s",
        }} className="h-full min-h-[calc(100vh-70px)] rounded-lg mr-2 sm:mr-5 bg-[#eef2f6]">
          <div className={`${collapsed ? "m-4 sm:m-6" : "m-4 sm:m-6"}`}>
            <Breadcrumbs />
            <div className="w-full mx-auto">
              <Outlet />
            </div>
          </div>
        </Layout>
        {/* <Layout.Footer
            style={{
              textAlign: "end",
              padding: "0px 8px",
              paddingBottom: "20px",
            }}
          >
            <Typography className="text-footer">
              © {currentYear} Bản quyền thuộc về SBA
            </Typography>
          </Layout.Footer> */}
      </Layout>
    </div>
  );
};

export default MainLayout;
