import statisticRoute from "pages/Statistic/route";
import usersRoute from "pages/Users/route";
import ordersRoute from "pages/Orders/route";
import cancelRoute from "pages/Cancel/route";
import serviceRoute from "pages/Services/route";
import paymentRoute from "pages/Payments/route";
import ticketRoute from "pages/Tickets/route";
import reportRoute from "pages/Reports/route";
import appearanceRoute from "pages/Appearance/route";
import settingRoute from "pages/Settings/route";

const route = [
  statisticRoute,
  usersRoute,
  ordersRoute,
  cancelRoute,
  serviceRoute,
  settingRoute,
  paymentRoute,
  ticketRoute,
  reportRoute,
  appearanceRoute,
];

export default route;
