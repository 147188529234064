import React from "react";
import { Layout, Tooltip } from "antd";
import { Link, NavLink } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import Logo from "assets/LogoSystem/min_logo.png";
// import bussinessReport from "assets/images/png/business-report.png";
// import processPng from "assets/images/png/process.png";
// import themesPng from "assets/images/png/themes.png";
import appDevelopPng from "assets/images/png/app-development.png";
import rechargePng from "assets/images/png/recharge.png";
import serverPng from "assets/images/png/server.png";
import userPng from "assets/images/png/user.png";
import trolleyPng from "assets/images/png/trolley.png";
import chatPng from "assets/images/png/chat.png"

import {
  USERS,
  ORDERS,
  // CANCEL,
  SERVICES,
  PAYMENTS,
  TICKETS,
  // REPORTS,
  // APPEARANCE,
  SETTINGS,
} from "pages/routes/route.constant";

const { Sider } = Layout;

interface NavItemProps {
  to: string;
  icon: string;
  label: string;
  collapsed: boolean;
}

const NavItem: React.FC<NavItemProps> = ({ to, icon, label, collapsed }) => (
  <Tooltip placement="right" title={collapsed ? label : null}>
    <NavLink
      to={to}
      className={({ isActive }) =>
        `flex items-center gap-4 p-3.5 ${collapsed ? "pl-3" : "pl-6 w-[88%]"} text-[14px] font-semibold rounded-lg transition-colors duration-300 ${isActive ? "bg-[#E6F4F1] text-[#2CA58D]" : "text-[#364152]"
        } hover:bg-[#E6F4F1] hover:text-[#2CA58D]`
      }
    >
      <img src={icon} width={25} height={25} alt="" />
      {!collapsed && <span>{label}</span>}
    </NavLink>
  </Tooltip>
);

interface SideBarProps {
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
  seoData?: any;
}

const navItemsData = [
  { to: USERS, icon: userPng, label: "Users" },
  { to: ORDERS, icon: trolleyPng, label: "Orders" },
  // { to: CANCEL, icon: processPng, label: "Cancel" },
  { to: SERVICES, icon: appDevelopPng, label: "Services" },
  { to: PAYMENTS, icon: rechargePng, label: "Payments" },
  { to: TICKETS, icon: chatPng, label: "Tickets" },
  // { to: REPORTS, icon: bussinessReport, label: "Reports" },
  // { to: APPEARANCE, icon: themesPng, label: "Appearance" },
  { to: SETTINGS, icon: serverPng, label: "Settings" },
];

const SideBar: React.FC<SideBarProps> = ({ collapsed, setCollapsed, seoData }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 640px)' })
  return (
    <Sider
      onCollapse={(value) => setCollapsed(value)}
      width={collapsed ? isTabletOrMobile ? 0 : 72 : isTabletOrMobile ? 256 : 256}
      theme="light"
      breakpoint="xl"
      collapsed={collapsed}
      collapsedWidth={isTabletOrMobile ? 0 : 72}
      className={`fixed h-screen bg-white pb-12 select-none z-[999]`}
      style={{ position: "fixed" }}
    >
      {!collapsed && isTabletOrMobile && (
        <Link className="flex w-full items-center my-2" to="/">
          <div className="ml-2 flex items-center w-full sm:ml-0">
            <img src={Logo} width={35} height={35} alt="" />
            {/* {!inMobileMode && ( */}
            <h1 className="ml-2 text-nowrap text-center text-lg font-semibold text-primary sm:ml-2 sm:mt-1 sm:text-xl lg:text-[24px]">
              {seoData?.title || "Min SMM"}
            </h1>
            {/* )} */}
          </div>
        </Link>
      )}
      {!collapsed && (
        <div className="w-full">
          <div className={`w-full p-2 ${collapsed ? "pl-1.5" : ""}`}>
            <h1
              className={`${collapsed ? "ml-0" : "ml-2"} text-[16px] font-medium text-[#364152]`}
            >
              Admin
            </h1>
          </div>
        </div>
      )}
      <div className="flex flex-col gap-1 justify-center items-center">
        {navItemsData.map((item) => (
          <NavItem
            key={item.to}
            to={item.to}
            icon={item.icon}
            label={item.label}
            collapsed={collapsed}
          />
        ))}
      </div>
    </Sider>
  );
};

export default SideBar;
