import { LOCAL_STORAGE_KEY } from "constants/enums";
import { USER_ROLE, USER_STATUS } from "constants/user";

const ConvertUserRole = (role: number | null) => {
  switch (role) {
    case USER_ROLE.admin_value:
      return USER_ROLE.admin_label;
    case USER_ROLE.user_value:
      return USER_ROLE.user_label;
    default:
      return "";
  }
};

const ConvertUserStatus = (status: number | null) => {
  switch (status) {
    case USER_STATUS.active_value:
      return USER_STATUS.active_label;
    case USER_STATUS.suspended_value:
      return USER_STATUS.suspended_label;
    case USER_STATUS.unconfirmed_value:
      return USER_STATUS.unconfirmed_label;
    case USER_STATUS.malicious_value:
      return USER_STATUS.malicious_label;
    default:
      return "";
  }
};

const GetApiKey = () => {
  const key = localStorage.getItem(LOCAL_STORAGE_KEY.API_KEY_ADMIN);
  return key ?? null;
};

const GetUserName = () => {
  const userName = localStorage.getItem(LOCAL_STORAGE_KEY.USERNAME_ADMIN);
  return userName ?? null;
};

const GetRoleUser = () => {
  const roleUser = localStorage.getItem(LOCAL_STORAGE_KEY.ROLE_ADMIN);
  if (roleUser) return parseInt(roleUser);
  return null;
};

const GetIdUser = () => {
  const idUser = localStorage.getItem(LOCAL_STORAGE_KEY.USER_ID_ADMIN);
  return idUser ?? null;
};

const GetEmailUser = () => {
  const idUser = localStorage.getItem(LOCAL_STORAGE_KEY.EMAIL_USER_ADMIN);
  return idUser ?? null;
};

export {
  ConvertUserRole,
  GetApiKey,
  GetIdUser,
  GetRoleUser,
  GetUserName,
  GetEmailUser,
  ConvertUserStatus,
};
