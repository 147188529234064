import {
  CameraOutlined as camera,
  ArrowRightOutlined as arrowRight,
  MenuOutlined as menuLine,
  SearchOutlined as searchIcon,
  ShoppingCartOutlined as shoppingIcon,
  BellOutlined as bellIcon,
  MailOutlined as mailIcon,
  UploadOutlined as uploadFileIcon,
  ExclamationCircleOutlined as exclameIcon,
  DownloadOutlined as download,
  MinusOutlined as sub,
  InfoCircleOutlined as inforCircle,
  InfoCircleTwoTone as inforTwoTone,
  DownOutlined as downOutline,
  DashOutlined as threeDash,
} from "@ant-design/icons";

export default {
  arrowRight,
  camera,
  menuLine,
  searchIcon,
  shoppingIcon,
  bellIcon,
  mailIcon,
  uploadFileIcon,
  exclameIcon,
  download,
  sub,
  inforCircle,
  downOutline,
  inforTwoTone,
  threeDash,
};
